


























import { AppModule } from '@/store/modules/app'
import { bindUtr } from '@/api'
import { Component, Vue } from 'vue-property-decorator'
@Component({
  name: 'india_submit'
})
export default class extends Vue {
  private loading: boolean = false
  private form: any = {
    utr: ''
  }

  private submit () {
    if (!this.form.utr || this.form.utr.length < 12) {
      this.$message({
        message: 'Please enter the 12-digit transaction UTR No.',
        type: 'warning'
      })
    } else {
      if (this.loading) {
        this.$message({
          message: 'Order has already bind UTR.',
          type: 'warning'
        })
      } else {
        this.loading = true
        bindUtr(this.form)
          .then(() => {
            this.loading = false
            this.$message({
              message: 'Submitted successfully.',
              type: 'success'
            })
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  }

  created () {
    AppModule.SetNavbar(false)
  }
  mounted () {}
}
